import React, { useState } from 'react';

import './Menu.scss';


const MenuButton = ({menuOpen, toggle}: {menuOpen: boolean, toggle: Function}) => (
  <div className={`toggle-menu ${menuOpen ? 'toggle-menu--open' : ''}`} onClick={() => toggle(!menuOpen)}>
    <div className='lines'>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
);

const LegendItem = ({ value, label, title }: {value: string; label: string; title: string}) => (
  <div title={title} className='legend__item'>
  <div className={`value value--${value}`}></div>
  <div className='label'>{label}</div>
  </div>
);


const Menu = () => {
  const [menuOpen, toggleMenu] = useState(window.innerWidth >= 1024);

  const toggle = (val: boolean) => toggleMenu(val);

  return (
    <>
    <MenuButton menuOpen={menuOpen} toggle={toggle} />
    <div className={`menu ${menuOpen ? 'menu--open' : ''}`}>
      <div className='menu__container'>
        <div className='legend'>
          <h3 className='legend__title'>Osallistujamäärät suhteutettuna asukaslukuun</h3>
          <LegendItem title='enemmän kuin 0.08%' value='red' label='Isolla joukolla verkostoitumassa' />
          <LegendItem title='0.04% - 0.08%' value='orange' label='Pienellä porukalla vaikuttamassa' />
          <LegendItem title='vähemmän kuin 0.04%' value='yellow' label='Joitakin ammattilaisia osallistumassa' />
          <div className='cta'>
            Eikö kuntasi näy kartalla? <a target="_blank" rel="noopener noreferrer" href="https://registration.contio.fi/kl-kustannus/Registration/Login?id=1006-KUMAVERKKO-28">Osallistu Kuntamarkkinoille &raquo;</a>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Menu;
