import React, { useState } from 'react';
import './Info.scss';


const InfoButton = ({infoOpen, toggle}: {infoOpen: boolean, toggle: Function}) => (
  <div className={`toggle-info ${infoOpen ? 'toggle-info--open' : ''}`} onClick={() => toggle(!infoOpen)}>
    <div className='icon'>
      i
    </div>
  </div>
);


const Info = () => {
  const [infoOpen, toggleInfo] = useState(false);

  const toggle = (val: boolean) => toggleInfo(val);

  return (
    <>
    <div className={`info ${infoOpen ? 'info--open' : ''}`}>
      <InfoButton infoOpen={infoOpen} toggle={toggle} />
      <div className='info__container'>
        Sisältää Maanmittauslaitoksen Kuntajako-aineistoa (8/2020) | Asukaslukujen lähde: Tilastokeskuksen väestörakennetilasto, Kuntaliitto | Map Tiles &copy; 2020 <a target="_blank" rel="noopener noreferrer" href="http://developer.here.com">HERE</a>
      </div>
    </div>
    </>
  )
}

export default Info;
