import React from 'react';
import { Map, TileLayer, GeoJSON, ZoomControl } from 'react-leaflet';
import L from 'leaflet';

import geojsonData from './osallistujadata.json';
import '../../../node_modules/leaflet/dist/leaflet.css';
import './Map.scss';

import Info from './Info';

const MAP_TILE_URL = 'https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/reduced.day/{z}/{x}/{y}/512/png8?apiKey=Ceze8AbBMJWrq0BtVhhL5U9-QRYPRDdXlVgHXVRPaco&ppi=320'
// const MAP_TILE_URL = 'https://stamen-tiles.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}.png'

const NAME_FIELD = 'asukaslukudata_Field2';
const POPULATION_FIELD = 'asukaslukudata_Field3';
const PARTICIPANTS_FIELD = 'asukaslukudata_Field4';
const FILL_OPACITY = 0.7;
const FILL_OPACITY_ACTIVE = 1;


const FINLAND_BBOX = [
  [59.846, 18.645],
  [70.060, 31.816],
];

// const MAX_BOUNDS = [
//   [
//     73.3,
//     9.3,
//   ],
//   [
//     53.6,
//     39.3,
//   ]
// ];

const isEmbed = () => {
  const urlParams = typeof URLSearchParams !== 'undefined' ? new URLSearchParams(window.location.search) : false;
  let embed = urlParams && urlParams.get('embed') !== null;
  // Check is iframe
  if (embed) {
    try {
      embed = window.self !== window.top;
    } catch (e) {
      embed = true;
    }
  }

  return embed;
}

const getStyle = ({ properties }) => {
  const population = properties[POPULATION_FIELD];
  const participants = properties[PARTICIPANTS_FIELD];
  let fillColor = "#fff";

  if (participants) {
    const index = (participants / population) * 100;
    if (index < 0.04) {
      fillColor = '#ffeac9';
    } else if (index >= 0.04 && index < 0.08) {
      fillColor = '#ff9b81';
    } else if (index >= 0.08) {
      fillColor = '#cd1719';
    }
  }

  return {
    fillColor,
    color: "#2a2c39",
    weight: 1,
    opacity: participants ? 0.6 : 0,
    fillOpacity: participants && FILL_OPACITY,
  };
}

const PopupContent = (props) => {
  const html = `
  <div>
    <h3>${props[NAME_FIELD]}</h3>
    <div><b>Osallistujamäärä:</b><span> ${props[PARTICIPANTS_FIELD]}</span></div>
    <div><b>Asukasluku:</b><span> ${props[POPULATION_FIELD]}</span></div>
  </div>
`;
  return html;
};

const onEachFeature = (f, layer) => {
  if (f.properties[PARTICIPANTS_FIELD]) {
    layer.on('mouseover', (e) => {
      layer.setStyle({
        fillOpacity: FILL_OPACITY_ACTIVE,
        weight: 2,
      });
    });

    layer.on('mouseout', (e) => {
      if (!layer.getPopup().isOpen()) {
        layer.setStyle({
          fillOpacity: FILL_OPACITY,
          weight: 1,
        });
      }
    });

    layer.bindPopup(PopupContent(f.properties))
    layer.bindTooltip(PopupContent(f.properties), { sticky: true, permanent: false });

    layer.getPopup().on('add', () => {
      layer.setStyle({
        fillOpacity: FILL_OPACITY_ACTIVE,
        weight: 2,
      });

      layer.getTooltip().remove();
    });
    layer.getPopup().on('remove', () => {
      layer.setStyle({
        fillOpacity: FILL_OPACITY,
        weight: 1,
      });
    });
  }
};

const Watermark = () => (
  <a href='https://www.zoneatlas.com' target='_blank' rel='noopener noreferrer' className='watermark'>ZONEATLAS <span className='hidden-mobile'>interactive maps</span></a>
);

class LeafletMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coordinates: [60.166640739, 24.943536799],
      zoom: 10,
      map: null,
    };
    this.mapRef = React.createRef();

    this.isEmbed = isEmbed();
  }

  render() {
    return <Map id="mapId"
      ref={this.mapRef}
      center={this.state.coordinates}
      zoom={this.state.zoom}
      zoomControl={false}
      attributionControl={false}
      minZoom={5}
      whenReady={({ target }) => {
        target.fitBounds(FINLAND_BBOX, { padding: [0, 0] });
        // target.setMaxBounds(MAX_BOUNDS);
      }}
    >
      <TileLayer url={MAP_TILE_URL} />
      <ZoomControl position='topright' />
      <Watermark />
      <Info />
      <GeoJSON onEachFeature={onEachFeature} renderer={L.canvas()} data={geojsonData} style={(feature) => getStyle(feature)} />
    </Map>
  }
}

export default LeafletMap;