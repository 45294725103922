import React from 'react';
import Map from './components/Map/';
import Menu from './components/Menu/';
import './App.scss';

function App() {
  return (
      <div className="App">
        <Menu />
        <Map />
      </div>
  );
}

export default App;
